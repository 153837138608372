export const dev = false;
const localDev = false;

const config = {
    api: dev&&localDev?
        "http://localhost:4000":
        "https://api.movies.schwarze-drya.de",
    
}

export default config;